@tailwind base;
@tailwind components;
@tailwind utilities;

.active {
    font-weight: bold;
    color: #f97316;
}

.previous {
    white-space: nowrap;
}

.next {
    white-space: nowrap;
}